<template>
  <v-col cols="12" class="px-0">
    <div class="about-container">
      <v-row>
        <v-col cols="12" sm="4">
          <v-card class="mx-auto" max-width="344" href="https://homesforterie.seehouseat.com/public/vtour/display/2223488?a=1#!/" target="_blank">
            <v-img src="images/photos/10.jpg" height="200px" cover alt=""></v-img>
            <v-card-title>Architecture</v-card-title>
            <!-- <v-card-subtitle class="text-uppercase">
              Subtitle Here
            </v-card-subtitle> -->
            <!-- <v-card-text>
              Description here.
            </v-card-text> -->
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="mx-auto" max-width="344" href="https://homesforterie.seehouseat.com/public/vtour/display/161594?a=1#!/" target="_blank">
            <v-img src="images/photos/9.jpg" height="200px" cover alt=""></v-img>
            <v-card-title>Hotels & Restaurants</v-card-title>
            <!-- <v-card-subtitle class="text-uppercase">
              Subtitle Here
            </v-card-subtitle> -->
            <!-- <v-card-text>
              Description here.
            </v-card-text> -->
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="mx-auto" max-width="344" href="https://homesforterie.seehouseat.com/public/vtour/display/103276?a=1#!/" target="_blank">
            <v-img src="images/photos/11.jpg" height="200px" cover alt=""></v-img>
            <v-card-title>Pools/Landscaping</v-card-title>
            <!-- <v-card-subtitle class="text-uppercase">
              Subtitle Here
            </v-card-subtitle> -->
            <!-- <v-card-text>
              Description here.
            </v-card-text> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.about-container {
  width: 100%;
  text-align: center;
  padding: 50px 200px;
  background-color: #f5f5f5;
}

@media only screen and (max-width: 1376px) {
  .about-container {
    padding: 50px;
  }
}

@media only screen and (max-width: 776px) {
  .about-container {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 635px) {
  .about-container {
    padding: 50px 25px;
  }
}
</style>