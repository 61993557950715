<template>
  <v-col cols="12" class="px-0">
    <div class="hire-banner" max-height="fit-content">
      <v-row>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <a 
            href="https://philipcomanphotography.com/grimsby-show.html" 
            target="_blank" 
            class="text-decoration-none text-h4 text-uppercase font-weight-light text-white align-self-center mr-10"
          >
            Art & Exhibition History
          </a>
        </v-col>

        <v-col cols="12" md="6">
          <a href="https://philipcomanphotography.com/grimsby-show.html" target="_blank">
            <v-img src="images/art3.jpg" contain width="285" alt="artistic construction photography gallery"/>
          </a>
        </v-col>
      </v-row>
    </div>
  </v-col>

  <v-col id="clients" sm="12" md="8" offset="0" offset-md="2" class="px-0 px-md-12 pt-4 pb-12">
    <h2 class="mb-3 text-center">Client's Testimonials</h2>

    <div style="width: 200px;" class="mx-auto">
      <v-slider v-model="sliderIcon" color="#d7ccc8"></v-slider>
    </div>

    <v-row>
      <v-carousel v-model="model" hide-delimiters class="px-2 mx-sm-12 px-md-0">
        <v-carousel-item
          v-for="(review, i) in clientReviews"
          :key="i"
          cover
        >
          <v-sheet
            height="100%"
            tile
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-col cols="12" class="mx-auto text-center">
                <h2 class="mb-3 text-center">
                  <v-icon aria-hidden="false" class="mr-1">fas fa-camera</v-icon>
                </h2>

                <hr width="110" class="mx-auto mb-3">

                <h3 class="mb-2">{{review.name}}:</h3>
                <p class="testimonial-text mx-6 mx-md-auto">"{{review.description}}"</p>

                <v-btn 
                  color="grey-lighten-3" 
                  class="mt-12 mx-auto" 
                  href="https://www.houzz.com/professionals/photographers/architectural-photography-by-phil-coman-pfvwus-pf~755879709#reviews" 
                  target="_blank"
                >
                  Houzz Reviews
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-col>

  <v-divider></v-divider>

  <h3 class="text-center mt-5 mb-6 font-weight-regular">Over 35 years experience working with the top architects, real estate agents and builders</h3>

  <v-img id="about" src="images/clients.jpg" height="650px" width="auto" class="mx-auto mb-5" alt=""></v-img>
  
  <h3 class="text-center mb-6">Real estate photography done to an architectural standard.</h3>

  <hr>

  <v-col cols="12" id="contact" class="px-10 px-sm-16">
    <v-row class="pl-lg-16">
      <v-col cols="12" lg="3" class="pl-lg-16 text-center">
        <div class="block">
          <h1>Contact Info</h1>
          <hr width="200" class="mx-auto mb-sm-12 mb-lg-0">
          <v-row>
            <v-col cols="12" sm="4" lg="12">
              <v-btn icon="fas fa-map-marker-alt" class="mt-8 mb-3 map-icon-pad icon-btn-pad" variant="outlined"/>
              <br>
              <span class="text-caption">Niagara Region, Canada</span>
              <br>
            </v-col>
            <v-col cols="12" sm="4" lg="12">
              <v-btn icon="fas fa-phone-alt" class="mt-8 mb-3 pb-1 icon-btn-pad" variant="outlined" href="tel:+9053253686"/>
              <br>
              <span class="text-caption">
                <a href="tel:+9053253686" class="text-decoration-none text-grey-darken-4">905-325-3686</a>
              </span>
              <br>
            </v-col>
            <v-col cols="12" sm="4" lg="12">
              <v-btn icon="fas fa-envelope" class="mt-8 mb-3 pb-1" variant="outlined" href="mailto:coman@vaxxine.com"/>
              <br>
              <span class="text-caption">
                <a href="mailto:coman@vaxxine.com" class="text-decoration-none text-grey-darken-4">coman@vaxxine.com</a>
              </span>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <!-- Contact Box Removed -->
      
    </v-row>
  </v-col>
</template>

<script>
import reviews from "./reviews.json";

export default {
  setup() {
    return {
      sliderIcon: 50,
      clientReviews: reviews
    }
  },
  data() {
    return {
      model: 0
    }
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start", });
    }
  }
}
</script>

<style scoped>
.hire-banner {
  width: 100%;
  height: fit-content;
  padding: 35px 14%;
  background-color: #800000;
  margin-top: -24px;
}

.hire-btn {
  position: absolute;
  right: 16px;
}

.testimonial-text {
  padding: 0 100px;
}

.block {
  width: 100%;
}

.icon-btn-pad {
  padding-right: 1px;
  padding-bottom: 2px;
}

.map-icon-pad {
  padding-bottom: 3px;
  padding-left: 2px;
}

.contact-box {
  padding-right: 170px;
}

.text-caption {
  font-size: 13px !important;
}

.contact-box .text-caption {
  font-size: 14px !important;
}

@media only screen and (max-width: 1279px) {
  .contact-box {
    padding-right: 0;
  }
}

@media only screen and (max-width: 959px) {
  .hire-btn {
    left: 10px;
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .testimonial-text {
    padding: 0 50px;
  }

  #about {
    transform: scale(1.5);
    right: 24%;
    max-height: 400px
  }
}
</style>