<template>
  <div class="hero">
    <v-row>
      <v-col cols="5" class="hero-text">
        <div style="position: relative" class="mx-auto">
          <h1 class="text-grey-lighten-3">Real Estate Photography</h1>
          <h1 class="text-yellow-lighten-3 mt-n2 mb-sm-1">Architecture</h1>
          <span class="text-grey-lighten-5 hero-desc">Aerial Drone Videography/Virtual Staging</span>
          <br>
          <v-btn tile dark color="yellow-lighten-3" class="mt-sm-4 mb-4 hero-btn" @click="scroll('contact')">Get A Quote</v-btn>
          
          <a class="d-block mb-2 text-grey-lighten-2 text-decoration-none" href="tel:+905-325-3686">
            <v-icon aria-hidden="false" class="mr-1">fas fa-phone</v-icon>
            905-325-3686
          </a>
          <div id="hero-email">
            <a class="d-block text-grey-lighten-2 text-decoration-none" href="mailto:coman@vaxxine.com">
              <v-icon aria-hidden="false" class="mr-1">fa-solid fa-envelope</v-icon>
              coman@vaxxine.com
            </a>
          </div>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="mb-4 d-none d-sm-block" style="position: absolute; z-index: 9999; bottom: 0; margin-left: auto; margin-right: auto; left: 0; right: 0; text-align: center;">
          <v-icon @click="scroll('services')">fas fa-angle-double-down</v-icon>
        </div>
      </v-col>
      <v-col cols="5">
        <div style="position: relative; z-index: 1;" class="mt-11 hero-img">
          <v-img src="images/photos/1.jpg" contain max-height="285" alt=""/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    methods: {
      scroll(refName) {
        const element = document.getElementById(refName);
        element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start", });
      }
    }
  }
</script>

<style scoped>
.hero {
  position: relative;
  text-align: center;
  padding-top: 12px;
  margin-bottom: 6px;
  height: 400px;
  width: 100%;
  color: white;
}

.hero:before {
  content: "";
  position: absolute;
  top: -1%;
  left: 0;
  height: 100%;
  width: 50%;
  background: black;
  transform: skew(0deg, 6deg);
}

.hero:after {
  content: "";
  position: absolute;
  top: -1%;
  right: 0;
  height: 100%;
  width: 50%;
  background: black;
  transform: skew(0deg, -6deg);
}

.hero-text {
  display: flex;
  align-items: center;
}

.hero-text > div {
  position: absolute;
  left: 5vw;
}

.v-row {
  height: 100%;
}

.hero-img {
    right: 5vw;
    display: flex;
    justify-content: center;
  }

@media only screen and (max-width: 905px) {
  .hero-text > div {
    left: 34px;
  }

  h1 {
    font-size: 30px;
  }

  .hero-img {
    margin-top: 0 !important;
    align-items: center;
    height: 100%;
    right: 34px;
  }
}

@media only screen and (max-width: 852px) {
  h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 599px) {
  .hero-text {
    position: relative;
    right: -3vw;
  }

  h1 {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 8px;
  }

  .hero-desc {
    font-size: 15px;
    position: relative;
    top: -5px;
  }

  .hero-btn {
    margin-top: 5px;
  }

  .v-btn--size-default {
    font-size: 12px;
  }

  .hero-text {
    text-align: center;
  }

  .hero-text i {
    font-size: 20px;
  }

  #hero-email {
    justify-self: center;
    position: absolute;
    width: 185px;
    z-index: 200;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .hero-img {
    width: 365px;
    right: 3vw;
  }
}
</style>