<template>
  <v-col cols="12" class="mt-8 mt-md-16 pt-md-7">
    <div>
      <v-row>
        <v-col cols="12" md="6" order="2" order-md="1" class="mt-8 mt-md-0">
          <v-img src="images/photos/2.jpg" max-height="400" class="intro-img-position" alt=""></v-img>
        </v-col>

        <v-col cols="12" md="6" order="1" order-md="2" class="pr-12 pl-12 pl-md-0 mb-16 mb-md-0">
          <h5 class="mt-md-16 text-uppercase mb-3 d-inline-block">Photo & Video (Virtual Tours and room staging)</h5>
          
          <h4 class="text-uppercase mb-1">Interiors</h4>
          <hr width="85" class="mb-3 border-gray">
          <h4 class="mt-n1 text-uppercase">Exteriors</h4>

          <p class="text-grey-darken-1 mt-2">
            Welcome to Philip Coman Photography, your ultimate destination for all your real estate and architectural photography needs in Niagara!
          </p>

          <br>

          <p class="text-grey-darken-1">
            35 years experience serving hundreds of real estate agents, architects, engineers, hotels, home builders. Clients include Four Points Sheraton, Oban Inn, Starwood Hotels, Assa Abloy Canada, Travelodge, Kenmore Homes, Mountainview Homes, Sotheby's, Colgate-Palmolive, amongst numerous others  
          </p>

          <v-btn tile dark color="#d7ccc8" class="mt-4" href="https://www.houzz.com/professionals/photographers/architectural-photography-by-phil-coman-pfvwus-pf~755879709" target="_blank">
            View Houzz.com Profile
          </v-btn>

          <v-btn tile dark color="#d7ccc8" class="mt-4 ml-4" href="/virtual-staging.html" target="_blank">
            Virtual Staging
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
  export default {
    setup() {
      return {
        sliderIcon: 50,
      }
    },
    methods: {
      scroll(refName) {
        const element = document.getElementById(refName);
        element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start", });
      }
    }
  }
</script>

<style scoped>
.intro-img-position {
  bottom: 0;
  left: 1%;
}

hr {
  border-top: 0;
  margin-top: 4px;
}

@media only screen and (max-width: 376px) {
  .intro-img-position {
    bottom: 102px;
    left: 5px;
  }
}
</style>