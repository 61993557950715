<template>
  <div class="text-center mt-12">
    <h2>What We Do</h2>
    <div style="width: 120px; margin: 0 auto;">
      <v-slider v-model="sliderIcon" color="#d7ccc8"></v-slider>
    </div>
  </div>

  <v-col cols="12" id="service-intro" class="pt-6 px-0">
    <div>
      <v-row>
        <v-col cols="12" class="pb-15">
          <v-card width="310px" class="block mx-auto mx-sm-5 mx-lg-8 pa-0 pb-4" href="http://homesforterie.seehouseat.com/public/vtour/display/2223488?a=1#!/" target="_blank">
            <v-img src="images/photos/21.jpg" cover height="200" alt="architecture photo and video services"/>
            <h3 class="mt-4">Building Architecture</h3>
            <!-- <p class="text-grey mt-4 text-caption">
              Description here.
            </p> -->
          </v-card>
          
          <v-card width="310px" class="block mx-auto mx-sm-5 mx-lg-8 pa-0 pb-4" href="/legacy.html#videos" target="_blank">
            <v-img src="images/photos/22.jpg" cover height="200" alt="real estate videography"/>
            <h3 class="mt-4">Drone Photography & Video</h3>      
            <!-- <p class="text-grey mt-4 text-caption">
              Description here.
            </p> -->
          </v-card>

          <v-card width="310px" class="block mx-auto mx-sm-5 mx-lg-8 mt-sm-8 mt-md-0 pa-0 pb-4" href="/hygrade.html" target="_blank">
            <v-img src="images/photos/23.jpg" cover height="200" alt="building construction photography"/>
            <h3 class="mt-4">Construction/Industrial</h3>
            <!-- <p class="text-grey mt-4 text-caption">
              Description here.
            </p> -->
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
  export default {
    setup() {
      return {
        sliderIcon: 50,
      }
    }
  }
</script>

<style scoped>
#service-intro {
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
}

.block {
  width: 285px;
}

p {
  font-size: 14px !important;
  line-height: 27px !important;
}

.icon-ml {
  margin-left: 9px;
}

.icon-pad {
  padding-right: 2px;
}

@media only screen and (max-width: 691px) {
  .block {
    padding-left: 0;
    padding-right: 0;
  }

  h3 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 531px) {
  .block {
    display: block;
    margin-bottom: 20px;
  }
}
</style>